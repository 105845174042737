import { WppIconChevron, WppNavSidebar, WppNavSidebarItem } from '@platform-ui-kit/components-library-react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useGoBackOrRedirect } from 'hooks/application/useGoBackOrRedirect'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import { isActivityItem } from 'pages/project/components/canvas/phaseUtils'
import { LinearData } from 'pages/project/components/canvas/utils'
import styles from 'pages/projectDemo/ProjectDemo.module.scss'
import { DemoItemType, DemoState } from 'pages/projectDemo/utils'
import { ApplicationItem, PhaseItem } from 'types/projects/workflow'
import { routesManager } from 'utils/routesManager'

interface Props {
  linearData: LinearData
  demoState: DemoState
  setDemoState: (state: DemoState) => void
  projectId: string
  selectedAppId?: string
  selectedPhaseId?: string
}

export const SideMenu = ({ linearData, demoState, setDemoState, projectId, selectedAppId, selectedPhaseId }: Props) => {
  const { t } = useTranslation()
  const goBackOrRedirect = useGoBackOrRedirect(routesManager.project.workflow.getURL({ id: projectId }))

  const handleSelectApp = useCallback(
    (appId: string, phaseId: string) => {
      setDemoState({
        type: DemoItemType.AppInfo,
        appId: appId,
        phaseId,
      })
    },
    [setDemoState],
  )

  useEffect(() => {
    if (selectedAppId && selectedPhaseId) {
      setDemoState({
        type: DemoItemType.AppInfo,
        appId: selectedAppId,
        phaseId: selectedPhaseId,
      })
    }
  }, [selectedAppId, selectedPhaseId, setDemoState])

  return (
    <WppNavSidebar className={styles.sidebar} data-testid="demo-sidebar">
      <WppNavSidebarItem
        onClick={goBackOrRedirect}
        label={t('project.demo.back_to_project')!}
        className={styles.grey800}
        data-testid="back-to-project"
      >
        <WppIconChevron direction="left" slot="icon-start" className={styles.icon} />
      </WppNavSidebarItem>
      <WppNavSidebarItem
        label={t('project.demo.project_details')!}
        active={demoState.type === DemoItemType.ProjectInfo}
        onClick={() => setDemoState({ type: DemoItemType.ProjectInfo })}
        data-testid="project-details"
      />

      {linearData.columnOrder.map(order => {
        const phase = linearData.columns[order]
        const phaseItems = linearData.columns[order].taskIds.map(id => linearData.tasks[id]) as PhaseItem[]

        return (
          <WppNavSidebarItem
            slot=""
            key={phase.id}
            path={phase.id}
            label={phase.name}
            extended={!!phaseItems.length}
            maxTitleLengthWithSubItems={21}
            data-testid={`phase-${phase.id}`}
            expanded={phase.id === selectedPhaseId}
          >
            {phaseItems.map((phaseItem, phaseItemIdx) => {
              const nextItem = phaseItems[phaseItemIdx + 1]

              if (isActivityItem(phaseItem)) {
                if (!phaseItem.item.items?.length) {
                  return null
                }

                // only show the divider if the next item is an activity with items or an application
                const divideNextItem = !!nextItem && (isActivityItem(nextItem) ? !!nextItem.item.items?.length : true)

                return phaseItem.item.items
                  .filter(el => !el.application.hidden)
                  .map((applicationItem, index, list) => {
                    return (
                      <WppNavSidebarItem
                        key={applicationItem.id}
                        path={applicationItem.id}
                        label={applicationItem.application.name}
                        groupTitle={!index ? phaseItem.item.name : void 0}
                        divide={divideNextItem && index === list.length - 1}
                        active={demoState.appId === applicationItem.application.id}
                        onClick={() => handleSelectApp(applicationItem.application.id, phaseItem.phaseId)}
                        data-testid={`app-${applicationItem.id}`}
                      >
                        <ApplicationLogo slot="icon-start" logo={applicationItem.application?.logoUrl} size={24} />
                      </WppNavSidebarItem>
                    )
                  })
              }

              const hasDivider = !!nextItem && isActivityItem(nextItem) && !!nextItem.item.items?.length
              const appItem = phaseItem.item as ApplicationItem

              if (appItem.hidden) return null

              return (
                <WppNavSidebarItem
                  key={appItem.id}
                  path={appItem.id}
                  label={appItem.name}
                  divide={hasDivider}
                  active={demoState.appId === appItem.id}
                  onClick={() => handleSelectApp(appItem.id, phaseItem.phaseId)}
                >
                  <div slot="icon-start">
                    <ApplicationLogo logo={appItem?.logoUrl} size={24} />
                  </div>
                </WppNavSidebarItem>
              )
            })}
          </WppNavSidebarItem>
        )
      })}
    </WppNavSidebar>
  )
}
