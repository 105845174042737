import {
  WppAvatarGroup,
  WppButton,
  WppCard,
  WppIconEdit,
  WppIconPlus,
  WppListItem,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { HierarchyLevelType } from '@wpp-open/core'
import { HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { RowClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Avatar, getAvatarColor } from 'components/common/avatar/Avatar'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, TableInfinite } from 'components/common/table'
import { TableKey } from 'constants/table'
import { useHierarchy } from 'hooks/useHierarchy'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useStableCallback } from 'hooks/useStableCallback'
import { showCreateProjectModal } from 'pages/components/projectModal/CreateProjectModal'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import { ProjectManageMenu } from 'pages/dashboard/components/projectManageMenu/ProjectManageMenu'
import { ProjectStatusTag } from 'pages/dashboard/components/projectsCardView/components/projectStatus/ProjectStatusTag'
import styles from 'pages/dashboard/components/projectsTableView/ProjectsTableVire.module.scss'
import { useProjectListLoader } from 'pages/dashboard/components/projectsTableView/useProjectListLoader'
import { ProjectTypeTag } from 'pages/dashboard/components/projectTypeTag/ProjectTypeTag'
import { useIsFiltersActive } from 'pages/dashboard/utils/useIsFiltersActive'
import { AppPermissions } from 'types/permissions/permissions'
import { Project, ProjectFilter, ProjectStatus } from 'types/projects/projects'
import { PhaseStatus } from 'types/projects/workflow'
import { fullName } from 'utils/common'
import { formatDate } from 'utils/dateFormat'
import { hasClosestInteractiveElement } from 'utils/dom'
import { routesManager } from 'utils/routesManager'

const NotDefined = () => (
  <WppTypography type="s-body" className={styles.notDefined}>
    -
  </WppTypography>
)

interface Props {
  filter: ProjectFilter
  setProjectsExist: (exist: boolean) => void
}

export const ProjectsTableView: FC<Props> = ({ filter, setProjectsExist }) => {
  const navigate = useNavigate()
  const { getWorkspaceTree, hierarchyOrder } = useHierarchy()
  const [isEmpty, setIsEmpty] = useState(false)

  const { isPermitted } = useIsPermitted()
  const canCreateProject =
    isPermitted(AppPermissions.ORCHESTRATION_PROJECTS_CREATE) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const { t } = useTranslation()
  const { loader } = useProjectListLoader({ filter })
  const isFilterActive = useIsFiltersActive(filter)

  const {
    osContext: {
      userDetails: { dateLocale },
    },
  } = useOs()

  const gridOptions = {
    rowStyle: { cursor: 'pointer' },
  }

  const firstInProgressPhase = useCallback(
    (project?: Project) =>
      project?.phases
        ?.sort((a, b) => a.orderNumber - b.orderNumber)
        .find(phase => phase.status === PhaseStatus.IN_PROGRESS),
    [],
  )

  const workspaceCell = useCallback(
    (nodeType: HierarchyLevelType, data?: Project) => {
      const tree = getWorkspaceTree(data?.contextWorkspace)
      const element = tree?.find(
        elem =>
          nodeType ===
          (elem.type.toLowerCase() === HierarchyCustomNodeType
            ? elem?.customTypeName?.toLowerCase()
            : elem.type.toLowerCase()),
      )

      if (element)
        return (
          <WppTypography type="s-body" className={styles.overflow} title={element?.name}>
            {element?.name}
          </WppTypography>
        )

      return <NotDefined />
    },
    [getWorkspaceTree],
  )

  const columnDefs = useMemo<ColDef<Project>[]>(() => {
    return [
      {
        colId: 'name',
        flex: 2,
        headerName: t('project.table.project_name')!,
        cellRenderer: ({ data }) => (
          <Flex direction="column">
            <WppTypography
              className={styles.overflow}
              type="s-body"
              title={data!.name}
              data-testid="table-project-name"
            >
              {data!.name}
            </WppTypography>
            {!!firstInProgressPhase(data)?.name && (
              <WppTypography
                className={clsx(styles.overflow, styles.grey800)}
                type="xs-body"
                title={data!.name}
                data-testid="table-project-active-pahse"
              >
                {firstInProgressPhase(data)?.name}
              </WppTypography>
            )}
          </Flex>
        ),
        tooltipValueGetter: ({ data }) => data?.name,
      },
      {
        colId: 'status',
        flex: 1,
        headerName: t('project.table.project_status')!,
        cellRenderer: ({ data }) => <ProjectStatusTag status={data!.status} className={styles.status} />,
      },
      {
        colId: 'type',
        flex: 1,
        headerName: t('project.table.project_type')!,
        cellRenderer: ({ data }) => <ProjectTypeTag type={data!.type} />,
        cellClassRules: {
          [styles.cell]: () => true,
        },
      },
      {
        colId: 'startDate',
        flex: 1,
        headerName: t('common.start_date')!,
        valueFormatter: ({ data }) => (data?.startDate ? formatDate(data.startDate, dateLocale)! : '-'),
      },
      {
        colId: 'endDate',
        flex: 1,
        headerName: t('common.end_date')!,
        valueFormatter: ({ data }) => (data?.endDate ? formatDate(data.endDate, dateLocale)! : '-'),
      },
      ...hierarchyOrder.reduce(
        (prev, curr) => [
          ...prev,
          {
            colId: curr,
            flex: 1,
            headerName: t(`modals.create_project.field_${curr}_label`, { defaultValue: curr })!,
            cellRenderer: ({ data }) => workspaceCell(curr, data),
          },
        ],
        [] as ColDef<Project>[],
      ),
      {
        colId: 'owner',
        flex: 1,
        headerName: t('project.table.project_owners')!,
        cellClassRules: {
          [styles.avatarCell]: () => true,
        },
        cellRenderer: ({ data }) => (
          <>
            {data?.owners?.length === 1 ? (
              <Flex align="center" gap={16} className={styles.overflow}>
                <Avatar
                  className={styles.noShrink}
                  size="xs"
                  name={fullName(data?.owner?.firstname, data?.owner?.lastname)}
                  src={data?.owner?.avatarUrl ?? ''}
                  withTooltip
                  tooltipConfig={{ placement: 'top' }}
                />
              </Flex>
            ) : (
              <WppAvatarGroup
                className={styles.otherMembers}
                onClick={e => {
                  e.stopPropagation()
                }}
                maxAvatarsToDisplay={4}
                size="xs"
                withTooltip
                users={data?.owners?.map(owner => ({
                  name: fullName(owner.firstname, owner.lastname),
                  src: owner.avatarUrl ?? '',
                  color: getAvatarColor({ name: owner.firstname }),
                }))}
              />
            )}
          </>
        ),
      },
      {
        cellRenderer: ({ data }) => (
          <>
            {data && (
              <ProjectManageMenu
                project={data}
                securedChildren={
                  data.status === ProjectStatus.ACTIVE && (
                    <WppListItem onWppChangeListItem={() => showProjectEditModal({ project: data })}>
                      <WppIconEdit slot="left" />
                      <WppTypography slot="label" type="s-body" data-testid="edit-action">
                        {t('project.list.btn_edit_project')}
                      </WppTypography>
                    </WppListItem>
                  )
                }
              />
            )}
          </>
        ),
        width: 60,
      },
    ]
  }, [t, firstInProgressPhase, dateLocale, hierarchyOrder, workspaceCell])

  const handleOnRowClicked = useStableCallback(({ event, data }: RowClickedEvent<Project>) => {
    const target = event?.target as HTMLElement

    if (data && !hasClosestInteractiveElement(target, ['.wpp-avatar-group'])) {
      navigate(routesManager.project.workflow.getURL({ id: data!.id }))
    }
  })

  const noRowsOverlayComponent = useCallback(() => {
    return (
      <EmptyState
        title={isFilterActive ? t('common.no_search_results') : t('project.list.empty_state_no_projects')}
        testToken="projects"
        filtersApplied={!!filter.search || isFilterActive}
        description={
          !!filter.search || isFilterActive
            ? t('common.no_results_description')
            : t('project.list.empty_state_no_projects_description')
        }
      >
        {!isFilterActive && canCreateProject && (
          <WppButton size="m" onClick={() => showCreateProjectModal()} data-testid="create-new-project">
            <WppIconPlus slot="icon-start" /> {t('dashboard.btn_add_project')}
          </WppButton>
        )}
      </EmptyState>
    )
  }, [canCreateProject, filter.search, isFilterActive, t])

  return (
    <WppCard className={clsx(styles.card, { [styles.emptyCard]: isEmpty })}>
      <TableInfinite
        gridOptions={gridOptions}
        tableKey={TableKey.PROJECT_LIST}
        loader={loader}
        cacheBlockSize={50}
        rowHeight={48}
        columnDefs={columnDefs}
        onRowClicked={handleOnRowClicked}
        noRowsOverlayComponent={noRowsOverlayComponent}
        headerHeight={isEmpty ? 0 : undefined}
        onLoadSuccess={({ isEmptySource }) => {
          setProjectsExist(!isEmptySource)
          setIsEmpty(isEmptySource && !filter?.search && !isFilterActive)
        }}
        className={clsx({ [styles.hideBorder]: isEmpty })}
      />
    </WppCard>
  )
}
