import {
  WppActionButton,
  WppCard,
  WppLabel,
  WppSkeleton,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useToggle } from 'react-use'

import { useAuthLinkApi } from 'api/wrike/queries/useAuthLinkApi'
import { useCheckMe } from 'auth/wrike/hooks/useCheckMe'
import { useWrikeRedirectUrl } from 'auth/wrike/utils'
import { showConfirmModal } from 'components/common/confirmModal/ConfirmModal'
import { Flex } from 'components/common/flex/Flex'
import { FormInput } from 'components/form/formInput/FormInput'
import { SvgWrikeLogo } from 'components/svg/WrikeLogo'
import styles from 'pages/components/projectModal/components/integrations/components/WrikeConnect.module.scss'
import { WrikeProjectCard } from 'pages/components/projectModal/components/integrations/components/WrikeProjectCard'
import { ProjectWrikeDTO } from 'types/projects/projects'

enum ConnectionState {
  NOT_AUTHED = 'NOT_AUTHED',
  NOT_LINKED = 'NOT_LINKED',
  LINK_ACTIVE = 'LINK_ACTIVE',
}

const HELP_LINK = 'https://help.wrike.com/hc/en-us/articles/1500005120641-Project-ID'

interface Props {
  wrikeError: MayBeNull<AxiosError<any>>
  wrike?: MayBeNull<ProjectWrikeDTO>
}

export const WrikeConnect = ({ wrikeError, wrike }: Props) => {
  const { t } = useTranslation()

  const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.NOT_AUTHED)
  const [isProjectLinkActive, toggleProjectLinkActive] = useToggle(!!wrike?.isConnected)
  const [showProjectIdInput, toggleProjectIdInput] = useToggle(false)

  const redirectUri = useWrikeRedirectUrl()
  const { data: wrikeLink } = useAuthLinkApi({
    params: { redirectUri },
  })

  const { myContact, isLoading: isTokenValidating } = useCheckMe()
  const isWrikeAuthed = !!myContact

  const { getValues, setValue, setError, clearErrors } = useFormContext()

  const { name } = getValues()

  useEffect(() => {
    setConnectionState(
      isWrikeAuthed
        ? isProjectLinkActive
          ? ConnectionState.LINK_ACTIVE
          : ConnectionState.NOT_LINKED
        : ConnectionState.NOT_AUTHED,
    )
  }, [isWrikeAuthed, isProjectLinkActive])

  useEffect(() => {
    if (!wrikeError) {
      clearErrors('wrikeProjectId')
      return
    }

    if (wrikeError?.response?.data?.detail?.code === 'WRIKE_ALREADY_ASSOCIATED') {
      setError('wrikeProjectId', { message: t('project.wrike.server_error.conflict') })
    } else {
      setError('wrikeProjectId', {
        message: t('project.wrike.server_error.sync_failed', { email: myContact?.primaryEmail }),
      })
    }
  }, [clearErrors, myContact?.primaryEmail, setError, t, wrikeError])

  const handleAuth = () => {
    window.open(wrikeLink!.url, '_blank')
    toggleProjectIdInput(true)
    setValue('disconnectWrike', false)
  }

  const handleConnect = () => {
    // wrike is already connected, just open an projectId input
    toggleProjectIdInput(true)
    setValue('disconnectWrike', false)
  }

  const handleCancel = () => {
    toggleProjectIdInput(false)
    setValue('disconnectWrike', true)
    !wrike?.isConnected && setValue('wrikeProjectId', '')
  }

  const handleDisconnect = () => {
    showConfirmModal({
      title: t('project.wrike.disconnect.title'),
      btnSubmitText: t('project.wrike.disconnect.button'),
      confirmMessage: (
        <Trans
          i18nKey="project.wrike.disconnect.message"
          values={{ name }}
          components={{ bold: <WppTypography type="m-strong" /> }}
        />
      ),
      handleSubmit: () => {
        toggleProjectIdInput(false)
        toggleProjectLinkActive(false)
        setValue('disconnectWrike', true)
      },
    })
  }

  if (isTokenValidating) {
    return <WppSkeleton variant="rectangle" height="32px" width="100%" />
  }

  return (
    <>
      <Flex justify="between">
        <Flex align="center">
          <SvgWrikeLogo className="wpp-spacing-8-right" /> {t('project.wrike.wrike')}
        </Flex>

        {connectionState === ConnectionState.LINK_ACTIVE && (
          <WppActionButton variant="destructive" onClick={handleDisconnect} data-testid="wrike-disconnect">
            {t('project.wrike.project_info.btn_disconnect')}
          </WppActionButton>
        )}

        {connectionState === ConnectionState.NOT_AUTHED && (
          <WppActionButton onClick={handleAuth} data-testid="wrike-connect" disabled={!wrikeLink}>
            {t('project.wrike.project_info.btn_connect')}
          </WppActionButton>
        )}

        {connectionState === ConnectionState.NOT_LINKED && (
          <>
            {!showProjectIdInput && (
              <WppActionButton onClick={handleConnect} data-testid="wrike-connect">
                {t('project.wrike.project_info.btn_connect')}
              </WppActionButton>
            )}

            {showProjectIdInput && (
              <WppActionButton variant="destructive" onClick={handleCancel} data-testid="wrike-cancel-connect">
                {t('common.btn_cancel')}
              </WppActionButton>
            )}
          </>
        )}
      </Flex>

      {connectionState === ConnectionState.LINK_ACTIVE && <WrikeProjectCard wrike={wrike} />}

      {connectionState === ConnectionState.NOT_LINKED && showProjectIdInput && (
        <WppCard variant="secondary" size="s">
          <Flex justify="between" className="wpp-spacing-8-bottom">
            <WppLabel
              config={{ text: t('project.wrike.project_info.wrike_project_id') }}
              optional
              typography="s-body"
            />

            <Link to={HELP_LINK} className={styles.helpLink} target="_blank" rel="noopener noreferrer">
              {t('project.wrike.project_info.help_link')}
            </Link>
          </Flex>

          {/* we can link project once a time, after that we cannot change the linked project ID*/}
          <FormInput
            name="wrikeProjectId"
            maxMessageLength={Number.POSITIVE_INFINITY}
            disabled={!!wrike?.wrikeProjectId}
            data-testid="wrike-project-id-input"
          />
        </WppCard>
      )}
    </>
  )
}
