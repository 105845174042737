import { PropsWithChildren, forwardRef } from 'react'

import { EditableApplication } from 'pages/project/components/canvas/components/item/application/EditableApplication'
import { PreviewApplication } from 'pages/project/components/canvas/components/item/application/PreviewApplication'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { TaskStatus } from 'types/projects/tasks'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  application: ApplicationItem
  showAction?: boolean
  isDisabled: boolean
  index: number
  changeAssignee?: (newAssignee: ResponsibleUser) => Promise<void>
  changeDates?: (dates: Date[]) => void
  changeStatus?: (status: TaskStatus) => void
  changeHidden?: (hidden: boolean) => void
  editApp?: () => void
  variant?: 'primary' | 'secondary'
  isEditable?: boolean
  preview?: boolean
  activityId?: string
  isActivityApplication?: boolean
  phaseId: string
  isInactive?: boolean
  isDraggingDisabled?: boolean
}

export const Application = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      application,
      showAction,
      isDisabled,
      index,
      variant,
      isEditable,
      preview,
      activityId,
      isActivityApplication,
      phaseId,
      isInactive,
      isDraggingDisabled,
      ...rest
    },
    ref,
  ) => {
    const { changeAssignee, changeDates, editApp, changeStatus, changeHidden } = rest

    return preview ? (
      <PreviewApplication application={application} isDisabled={isDisabled} activityId={activityId} phaseId={phaseId} />
    ) : (
      <EditableApplication
        application={application}
        isDisabled={isDisabled}
        index={index}
        variant={variant}
        isEditable={isEditable}
        isInactive={isInactive}
        editApp={editApp}
        changeAssignee={changeAssignee!}
        changeDates={changeDates!}
        changeHidden={changeHidden!}
        showAction={showAction!}
        phaseId={phaseId}
        activityId={activityId}
        ref={ref}
        isActivityApplication={isActivityApplication}
        changeStatus={changeStatus!}
        isDraggingDisabled={isDraggingDisabled}
      />
    )
  },
)
